<template>
  <div class="row flex-column flex-md-row h-100 justify-content-between">
    <div class="col col-md-4 mb-4 mb-md-0">
      <div class="row flex-column justify-content-around">
        <h3>Text Generation API</h3>
        <div class="mb-3">
          The text rendering API is generated by our large-scale unsupervised language model that can generate paragraphs of text.
          takes a sentence or partial sentence and guesses the text after that input.
        </div>
        <div class="col col-md-8">
          <b-form-tags
              class="form-control mb-3 border-dark m-input "
              autocomplete="off"
              id="text-generation"
              data-role="tagsinput"
              name="textGeneration"
              v-model="tags"
              data-search-key="textGeneration">
          </b-form-tags>
          <div class="d-flex justify-content-center">
            <button :disabled="isDisabled" @click="getData()" id="text-gen-submit"
                    class="btn d-flex rounded text-dark justify-content-center align-items-center btn-secondary">
              Generate
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="col">
      <div
          class="rounded  px-4 py-4 social-link-container border border-dark  d-flex flex-column justify-content-between">
        <div>
             <textarea class="form-control "  id="storage" style="position:absolute; top: -1000px" ></textarea>
          <textarea class="form-control data-textarea"  :value="apiData"
                    ref="apiData" rows="3" id="textgeneration"></textarea>
          <div v-if="isLoading" class="spinner-border" role="status"></div>
          <span class="sr-only" v-show="isLoaded" @load="loaded"> </span>
        </div>
        <div class="d-flex  justify-content-end">
          <div class="d-flex icons-container">
            <ShareNetwork
                network="twitter"
                :title=apiSelectedData
            >
              <i class="flaticon-twitter-logo"></i>
            </ShareNetwork>

            <ShareNetwork
                network="facebook"
                url="https://booklogic.net"
                :title=apiSelectedData
            >
              <i class="flaticon-facebook-letter-logo"></i>
            </ShareNetwork>

            <ShareNetwork
                network="linkedin"
                url="https://booklogic.net"
                :title=apiSelectedData
            >
              <i class="flaticon-linkedin-logo"></i>
            </ShareNetwork>
            <a class="copy" @click="copy">
              <i class="flaticon2-copy"></i>
            </a>

            <a :href="''"><i class="flaticon2-print"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {coreJs} from "utilPath/coreJs";
import {_elementUtil} from "../../../tools/src/core/util/element-util";

export default {
  data() {
    return {
      name: "Text Generation",
      title: "Text Generation",
      apiData: "",
        apiSelectedData:"",
      tags: [],
      isLoaded: false,
      isLoading: false,
      isDisabled: false,
      baseUrl: window.location.href,
      lengthClick: 0,
      lengthCopy: 0,
      arrCopy: [],
    }
  },
  mounted() {
    coreJs.init({name: this.name, title: this.title});
    this.initArrCopy();
  },
  methods: {
    initArrCopy() {
      const arrCopy = localStorage.getItem("arrCopy");
      if (arrCopy) {
        this.arrCopy = JSON.parse(arrCopy);

        // update current click when reload page
        const currentCopy = this.arrCopy[this.arrCopy.length - 1];
        if ((new Date).toDateString() === currentCopy.date) {
          this.lengthClick = currentCopy.lengthClick;
          this.lengthCopy = currentCopy.lengthCopy;
        }
      }
    },
    getData: function () {
      this.isDisabled = true
      let module = this;
      this.isLoaded = false;
      this.isLoading = true;
      _elementUtil.postService(this.$envConfig.API_URL + '/text-generation', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          "keywords": module.tags
        }),
        callBack: (elt, response) => {

          module.apiData = response.data.join("\n\n");
          module.isLoaded = true;
          module.isLoading = false;
          module.isDisabled = false;
          this.lengthClick++;
          this.save();

        }
      });
    },
    copy() {
    //  this.$refs.apiData.focus();
        const element=document.getElementById("textgeneration");
      //  element.select();
        element.setSelectionRange(   element.selectionStart,element.selectionEnd);
        var sel = element.value.substring(element.selectionStart, element.selectionEnd);
        const storage = document.getElementById('storage');
        storage.value=sel;
        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.apiSelectedData=sel;
      this.lengthCopy++;
      this.save();
    },
    save() {
      const dataClick = {
        date: (new Date).toDateString(),
        lengthClick: this.lengthClick,
        lengthCopy: this.lengthCopy,
      }
      if (this.arrCopy.length === 0) {
        this.arrCopy.push(dataClick);
      } else {
        if (dataClick.date !== this.arrCopy[this.arrCopy.length - 1].date) {
          this.arrCopy.push(dataClick);
        } else {
          this.arrCopy[this.arrCopy.length - 1] = dataClick;
        }

      }

      //this.arrCopy.push(this.l);
      //this.lengthClick = 0;
      localStorage.setItem("arrCopy", JSON.stringify(this.arrCopy))
    },
    loaded() {
      this.isLoaded = true;
      this.isLoading = false;
    },
  },

}

</script>

<style>
#text-gen-submit {
  width: 100px;
  height: 30px;
}

.social-link-container {
  min-height: 300px;
}

.icons-container i {
  height: 10px;
  width: 10px;
  color: black;
  margin-right: 20px;
}

.rounded {
  position: relative;
}

.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
}

.data-textarea {
  background: transparent !important;
  border: none !important;
  height: 230px !important;
}

.copy {
  cursor: pointer;
}

textarea {
  resize: none;
}
</style>

l